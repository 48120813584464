import '@/styles/pages/Top/Top.scss';
import '@/styles/pages/Event/Event.scss';

import React, { useContext, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import ImgUserIcon from '@/assets/images/profile/circle-placeholder.svg';
import BaseButton from '@/components/common/Button/BaseButton';
import { DataLayerPushContainer } from '@/components/common/DataLayerPushContainer';
import Header from '@/components/common/Header';
import { Footer } from '@/components/common/Top/Footer';
import { HelmetContainer } from '@/components/common/Head/Helmet';
import { ConfigContext } from '@/utils/config';
import { State } from '@/redux/state';
import { eventList } from '@/definition/EVENT_LIST';

import { Ed1 } from '@/components/Event/Ed1';
import { Ed2 } from '@/components/Event/Ed2';
import { Ed3 } from '@/components/Event/Ed3';
import { Ed4 } from '@/components/Event/Ed4';
import { Ed5 } from '@/components/Event/Ed5';
import { Ed6 } from '@/components/Event/Ed6';
import { Ed7 } from '@/components/Event/Ed7';
import { Ed8 } from '@/components/Event/Ed8';
import { Ed9 } from '@/components/Event/Ed9';
import { Ed10 } from '@/components/Event/Ed10';
import { Ed11 } from '@/components/Event/Ed11';
import { Ed12 } from '@/components/Event/Ed12';
import { Ed13 } from '@/components/Event/Ed13';
import { Ed14 } from '@/components/Event/Ed14';
import { Ed15 } from '@/components/Event/Ed15';
import { Ed16 } from '@/components/Event/Ed16';
import { Ed17 } from '@/components/Event/Ed17';
import { Ed18 } from '@/components/Event/Ed18';
import { Ed19 } from '@/components/Event/Ed19';
import { Ed20 } from '@/components/Event/Ed20';
import { Ed21 } from '@/components/Event/Ed21';
import { Ed22 } from '@/components/Event/Ed22';
import { Ed23 } from '@/components/Event/Ed23';
import { Ed24 } from '@/components/Event/Ed24';
import { Ed25 } from '@/components/Event/Ed25';
import { Ed26 } from '@/components/Event/Ed26';
import { Ed27 } from '@/components/Event/Ed27';
import { Ed28 } from '@/components/Event/Ed28';
import { Ed29 } from '@/components/Event/Ed29';
import { Ed30 } from '@/components/Event/Ed30';
import { Ed31 } from '@/components/Event/Ed31';
import { Ed32 } from '@/components/Event/Ed32';
import { Ed33 } from '@/components/Event/Ed33';
import { Ed34 } from '@/components/Event/Ed34';
import { Ed35 } from '@/components/Event/Ed35';
import { Ed36 } from '@/components/Event/Ed36';
import { Ed37 } from '@/components/Event/Ed37';
import { Ed38 } from '@/components/Event/Ed38';
import { Ed39 } from '@/components/Event/Ed39';
import { Ed40 } from '@/components/Event/Ed40';
import { Ed41 } from '@/components/Event/Ed41';
import { Ed42 } from '@/components/Event/Ed42';
import { Ed43 } from '@/components/Event/Ed43';
import { Ed44 } from '@/components/Event/Ed44';
import { Ed45 } from '@/components/Event/Ed45';
import { Ed46 } from '@/components/Event/Ed46';
import { Ed47 } from '@/components/Event/Ed47';
import { Ed48 } from '@/components/Event/Ed48';
import { Ed49 } from '@/components/Event/Ed49';
import { Ed50 } from '@/components/Event/Ed50';
import { Ed51 } from '@/components/Event/Ed51';
import { Ed52 } from '@/components/Event/Ed52';
import { Ed53 } from '@/components/Event/Ed53';
import { Ed54 } from '@/components/Event/Ed54';
import { Ed55 } from '@/components/Event/Ed55';
import { Ed56 } from '@/components/Event/Ed56';
import { Ed57 } from '@/components/Event/Ed57';
import { Ed58 } from '@/components/Event/Ed58';
import { Ed59 } from '@/components/Event/Ed59';
import { Ed60 } from '@/components/Event/Ed60';
import { Ed61 } from '@/components/Event/Ed61';
import { Ed62 } from '@/components/Event/Ed62';
import { Ed63 } from '@/components/Event/Ed63';
import { Ed64 } from '@/components/Event/Ed64';
import { Ed65 } from '@/components/Event/Ed65';
import { Ed66 } from '@/components/Event/Ed66';
import { Ed67 } from '@/components/Event/Ed67';
import { Ed68 } from '@/components/Event/Ed68';
import { Ed69 } from '@/components/Event/Ed69';
import { Ed70 } from '@/components/Event/Ed70';
import { Ed71 } from '@/components/Event/Ed71';
import { Ed72 } from '@/components/Event/Ed72';
import { Ed73 } from '@/components/Event/Ed73';
import { Ed74 } from '@/components/Event/Ed74';
import { Ed75 } from '@/components/Event/Ed75';
import { Ed76 } from '@/components/Event/Ed76';
import { Ed77 } from '@/components/Event/Ed77';
import { Ed78 } from '@/components/Event/Ed78';
import { Ed79 } from '@/components/Event/Ed79';
import { Ed80 } from '@/components/Event/Ed80';
import { Ed81 } from '@/components/Event/Ed81';
import { Ed82 } from '@/components/Event/Ed82';
import { Ed83 } from '@/components/Event/Ed83';
import { Ed84 } from '@/components/Event/Ed84';
import { Ed85 } from '@/components/Event/Ed85';
import { Ed86 } from '@/components/Event/Ed86';
import { Ed87 } from '@/components/Event/Ed87';
import { Ed88 } from '@/components/Event/Ed88';
import { Ed89 } from '@/components/Event/Ed89';
import { Ed90 } from '@/components/Event/Ed90';
import { Ed91 } from '@/components/Event/Ed91';
import { Ed92 } from '@/components/Event/Ed92';
import { Ed93 } from '@/components/Event/Ed93';
import { Ed94 } from '@/components/Event/Ed94';
import { Ed95 } from '@/components/Event/Ed95';
import { Ed96 } from '@/components/Event/Ed96';
import { Ed97 } from '@/components/Event/Ed97';
import { Ed98 } from '@/components/Event/Ed98';
import { Ed99 } from '@/components/Event/Ed99';
import { Ed100 } from '@/components/Event/Ed100';
import { Ed101 } from '@/components/Event/Ed101';
import { Ed102 } from '@/components/Event/Ed102';
import { Ed103 } from '@/components/Event/Ed103';
import { Ed104 } from '@/components/Event/Ed104';
import { Ed105 } from '@/components/Event/Ed105';
import { Ed106 } from '@/components/Event/Ed106';
import { Ed107 } from '@/components/Event/Ed107';
import { Ed108 } from '@/components/Event/Ed108';
import { Ed109 } from '@/components/Event/Ed109';
import { Ed110 } from '@/components/Event/Ed110';
import { Ed111 } from '@/components/Event/Ed111';
import { Ed112 } from '@/components/Event/Ed112';
import { Ed113 } from '@/components/Event/Ed113';
import { Ed114 } from '@/components/Event/Ed114';
import { Ed115 } from '@/components/Event/Ed115';
import { Ed116 } from '@/components/Event/Ed116';
import { Ed117 } from '@/components/Event/Ed117';
import { Ed118 } from '@/components/Event/Ed118';
import { Ed119 } from '@/components/Event/Ed119';
import { Ed120 } from '@/components/Event/Ed120';
import { Ed121 } from '@/components/Event/Ed121';
import { Ed122 } from '@/components/Event/Ed122';
import { Ed123 } from '@/components/Event/Ed123';
import { Ed124 } from '@/components/Event/Ed124';
import { Ed125 } from '@/components/Event/Ed125';
import { Ed126 } from '@/components/Event/Ed126';
import { Ed127 } from '@/components/Event/Ed127';
import { Ed128 } from '@/components/Event/Ed128';
import { Ed129 } from '@/components/Event/Ed129';
import { Ed130 } from '@/components/Event/Ed130';
import { Ed131 } from '@/components/Event/Ed131';
import { Ed132 } from '@/components/Event/Ed132';
import { Ed133 } from '@/components/Event/Ed133';
import { Ed134 } from '@/components/Event/Ed134';
import { Ed135 } from '@/components/Event/Ed135';

export const EventDetail = (): React.ReactElement => {
  const params: { id: string } = useParams();
  const components = [
    {
      id: '0001',
      component: <Ed1 />,
    },
    {
      id: '0002',
      component: <Ed2 />,
    },
    {
      id: '0003',
      component: <Ed3 />,
    },
    {
      id: '0004',
      component: <Ed4 />,
    },
    {
      id: '0005',
      component: <Ed5 />,
    },
    {
      id: '0006',
      component: <Ed6 />,
    },
    {
      id: '0007',
      component: <Ed7 />,
    },
    {
      id: '0008',
      component: <Ed8 />,
    },
    {
      id: '0009',
      component: <Ed9 />,
    },
    {
      id: '0010',
      component: <Ed10 />,
    },
    {
      id: '0011',
      component: <Ed11 />,
    },
    {
      id: '0012',
      component: <Ed12 />,
    },
    {
      id: '0013',
      component: <Ed13 />,
    },
    {
      id: '0014',
      component: <Ed14 />,
    },
    {
      id: '0015',
      component: <Ed15 />,
    },
    {
      id: '0016',
      component: <Ed16 />,
    },
    {
      id: '0017',
      component: <Ed17 />,
    },
    {
      id: '0018',
      component: <Ed18 />,
    },
    {
      id: '0019',
      component: <Ed19 />,
    },
    {
      id: '0020',
      component: <Ed20 />,
    },
    {
      id: '0021',
      component: <Ed21 />,
    },
    {
      id: '0022',
      component: <Ed22 />,
    },
    {
      id: '0023',
      component: <Ed23 />,
    },
    {
      id: '0024',
      component: <Ed24 />,
    },
    {
      id: '0025',
      component: <Ed25 />,
    },
    {
      id: '0026',
      component: <Ed26 />,
    },
    {
      id: '0027',
      component: <Ed27 />,
    },
    {
      id: '0028',
      component: <Ed28 />,
    },
    {
      id: '0029',
      component: <Ed29 />,
    },
    {
      id: '0030',
      component: <Ed30 />,
    },
    {
      id: '0031',
      component: <Ed31 />,
    },
    {
      id: '0032',
      component: <Ed32 />,
    },
    {
      id: '0033',
      component: <Ed33 />,
    },
    {
      id: '0034',
      component: <Ed34 />,
    },
    {
      id: '0035',
      component: <Ed35 />,
    },
    {
      id: '0036',
      component: <Ed36 />,
    },
    {
      id: '0037',
      component: <Ed37 />,
    },
    {
      id: '0038',
      component: <Ed38 />,
    },
    {
      id: '0039',
      component: <Ed39 />,
    },
    {
      id: '0040',
      component: <Ed40 />,
    },
    {
      id: '0041',
      component: <Ed41 />,
    },
    {
      id: '0042',
      component: <Ed42 />,
    },
    {
      id: '0043',
      component: <Ed43 />,
    },
    {
      id: '0044',
      component: <Ed44 />,
    },
    {
      id: '0045',
      component: <Ed45 />,
    },
    {
      id: '0046',
      component: <Ed46 />,
    },
    {
      id: '0047',
      component: <Ed47 />,
    },
    {
      id: '0048',
      component: <Ed48 />,
    },
    {
      id: '0049',
      component: <Ed49 />,
    },
    {
      id: '0050',
      component: <Ed50 />,
    },
    {
      id: '0051',
      component: <Ed51 />,
    },
    {
      id: '0052',
      component: <Ed52 />,
    },
    {
      id: '0053',
      component: <Ed53 />,
    },
    {
      id: '0054',
      component: <Ed54 />,
    },
    {
      id: '0055',
      component: <Ed55 />,
    },
    {
      id: '0056',
      component: <Ed56 />,
    },
    {
      id: '0057',
      component: <Ed57 />,
    },
    {
      id: '0058',
      component: <Ed58 />,
    },
    {
      id: '0059',
      component: <Ed59 />,
    },
    {
      id: '0060',
      component: <Ed60 />,
    },
    {
      id: '0061',
      component: <Ed61 />,
    },
    {
      id: '0062',
      component: <Ed62 />,
    },
    {
      id: '0063',
      component: <Ed63 />,
    },
    {
      id: '0064',
      component: <Ed64 />,
    },
    {
      id: '0065',
      component: <Ed65 />,
    },
    {
      id: '0066',
      component: <Ed66 />,
    },
    {
      id: '0067',
      component: <Ed67 />,
    },
    {
      id: '0068',
      component: <Ed68 />,
    },
    {
      id: '0069',
      component: <Ed69 />,
    },
    {
      id: '0070',
      component: <Ed70 />,
    },
    {
      id: '0071',
      component: <Ed71 />,
    },
    {
      id: '0072',
      component: <Ed72 />,
    },
    {
      id: '0073',
      component: <Ed73 />,
    },
    {
      id: '0074',
      component: <Ed74 />,
    },
    {
      id: '0075',
      component: <Ed75 />,
    },
    {
      id: '0076',
      component: <Ed76 />,
    },
    {
      id: '0077',
      component: <Ed77 />,
    },
    {
      id: '0078',
      component: <Ed78 />,
    },
    {
      id: '0079',
      component: <Ed79 />,
    },
    {
      id: '0080',
      component: <Ed80 />,
    },
    {
      id: '0081',
      component: <Ed81 />,
    },
    {
      id: '0082',
      component: <Ed82 />,
    },
    {
      id: '0083',
      component: <Ed83 />,
    },
    {
      id: '0084',
      component: <Ed84 />,
    },
    {
      id: '0085',
      component: <Ed85 />,
    },
    {
      id: '0086',
      component: <Ed86 />,
    },
    {
      id: '0087',
      component: <Ed87 />,
    },
    {
      id: '0088',
      component: <Ed88 />,
    },
    {
      id: '0089',
      component: <Ed89 />,
    },
    {
      id: '0090',
      component: <Ed90 />,
    },
    {
      id: '0091',
      component: <Ed91 />,
    },
    {
      id: '0092',
      component: <Ed92 />,
    },
    {
      id: '0093',
      component: <Ed93 />,
    },
    {
      id: '0094',
      component: <Ed94 />,
    },
    {
      id: '0095',
      component: <Ed95 />,
    },
    {
      id: '0096',
      component: <Ed96 />,
    },
    {
      id: '0097',
      component: <Ed97 />,
    },
    {
      id: '0098',
      component: <Ed98 />,
    },
    {
      id: '0099',
      component: <Ed99 />,
    },
    {
      id: '0100',
      component: <Ed100 />,
    },
    {
      id: '0101',
      component: <Ed101 />,
    },
    {
      id: '0102',
      component: <Ed102 />,
    },
    {
      id: '0103',
      component: <Ed103 />,
    },
    {
      id: '0104',
      component: <Ed104 />,
    },
    {
      id: '0105',
      component: <Ed105 />,
    },
    {
      id: '0106',
      component: <Ed106 />,
    },
    {
      id: '0107',
      component: <Ed107 />,
    },
    {
      id: '0108',
      component: <Ed108 />,
    },
    {
      id: '0109',
      component: <Ed109 />,
    },
    {
      id: '0110',
      component: <Ed110 />,
    },
    {
      id: '0111',
      component: <Ed111 />,
    },
    {
      id: '0112',
      component: <Ed112 />,
    },
    {
      id: '0113',
      component: <Ed113 />,
    },
    {
      id: '0114',
      component: <Ed114 />,
    },
    {
      id: '0115',
      component: <Ed115 />,
    },
    {
      id: '0116',
      component: <Ed116 />,
    },
    {
      id: '0117',
      component: <Ed117 />,
    },
    {
      id: '0118',
      component: <Ed118 />,
    },
    {
      id: '0119',
      component: <Ed119 />,
    },
    {
      id: '0120',
      component: <Ed120 />,
    },
    {
      id: '0121',
      component: <Ed121 />,
    },
    {
      id: '0122',
      component: <Ed122 />,
    },
    {
      id: '0123',
      component: <Ed123 />,
    },
    {
      id: '0124',
      component: <Ed124 />,
    },
    {
      id: '0125',
      component: <Ed125 />,
    },
    {
      id: '0126',
      component: <Ed126 />,
    },
    {
      id: '0127',
      component: <Ed127 />,
    },
    {
      id: '0128',
      component: <Ed128 />,
    },
    {
      id: '0129',
      component: <Ed129 />,
    },
    {
      id: '0130',
      component: <Ed130 />,
    },
    {
      id: '0131',
      component: <Ed131 />,
    },
    {
      id: '0132',
      component: <Ed132 />,
    },
    {
      id: '0133',
      component: <Ed133 />,
    },
    {
      id: '0134',
      component: <Ed134 />,
    },
    {
      id: '0135',
      component: <Ed135 />,
    },
  ];

  const isAuth = useSelector((state: State) => state.isAuth);
  const user = useSelector((state: State) => state.user);

  const config = useContext(ConfigContext);

  const menu = useMemo(() => {
    if (isAuth) {
      return config.menu;
    } else {
      return [
        {
          title: 'ログイン',
          url: '/login',
        },
      ];
    }
  }, [isAuth]);

  const headerMenu = useMemo(() => {
    if (isAuth) {
      return config.headerMenu;
    } else {
      return config.notAuthMenu;
    }
  }, [isAuth]);

  const userIcon = useMemo(() => {
    if (!user) return ImgUserIcon;
    return user?.icon;
  }, [user]);

  const badge = useMemo(() => {
    if (isAuth) {
      return true;
    } else {
      return false;
    }
  }, [isAuth]);

  const children = useMemo(() => {
    if (!isAuth) {
      return <BaseButton theme="primary" size="40" className="header-btn" href="/entry">
        MATCHBOXをはじめる
      </BaseButton>;
    }
  }, [isAuth]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [params.id]);

  useEffect(() => {
    const event = eventList.find((e) => e.id === params.id);
    if (!event) return;
    const dateFormat = (date: string) => {
      const splitDate = date.split(' ');
      const joinDate = splitDate.join('T') + '-09:00';
      return joinDate;
    }
    const body = document.querySelector('body');
    const script = document.createElement('script');
    script.setAttribute('type', 'application/ld+json');
    script.textContent = `{
      "@context": "https://schema.org",
      "@type": "Event",
      "name": "${event.title.filter((t) => t !== '<br />').join('')}",
      "startDate": "${dateFormat(event.openingDate)}",
      "endDate": "${dateFormat(event.deadlineDate)}",
      "eventStatus": "https://schema.org/EventScheduled",
      "eventAttendanceMode": "https://schema.org/OnlineEventAttendanceMode",
      "location": {
        "@type": "VirtualLocation",
        "url": "https://zoom.us"
        },
      "image": [
        "https://matchbox.work/images/Top/event/event_${event.id}_mv.png"
       ],
      "description": "${event.description}",
      "offers": {
        "@type": "Offer",
        "url": "https://matchbox.work/event/${event.id}",
        "price": "0",
        "priceCurrency": "JPY",
        "availability": "https://schema.org/InStock",
        "validFrom": "${dateFormat(event.releaseDate)}"
      },
      "performer": {
        "@type": "${event.performerType}",
        "name": "${event.performerName}"
      },
      "organizer": {
        "@type": "Organization",
        "name": "株式会社マイナビワークス マッチボックスセミナー事務局",
        "url": "https://matchbox.work/"
      }
    }`;
    if (body) body.appendChild(script);
  }, []);

  return (
    <HelmetContainer>
      <section className={`event ${isAuth ? "event-auth" : ""}`}>
        <DataLayerPushContainer
          data={{
            event: 'pageView',
            actionType: 'page_view',
            actionName: 'page_view',
          }}
        >
          <Header
            menu={menu}
            headerMenu={headerMenu}
            userIcon={userIcon}
            badge={badge}
            isAuth={isAuth}
          >
            {children}
          </Header>
          <section className={`max-w-980 m-auto md:px-16 md:mt-80 sm:px-16 mb-120 sm:mb-64 sm:mt-55 ${!isAuth ? "mt-80" : ""}`}>
            {components.find((c) => c.id === params.id)?.component}
          </section>
          <Footer />
        </DataLayerPushContainer>
      </section>
    </HelmetContainer>
  );
};
