import React from 'react';
import { useHistory } from 'react-router';
import Modal from '@/components/common/Modal';
import { BaseButton } from '@/components/common/Button/';
import { DataLayerPushContainer } from '../../DataLayerPushContainer';
import { PortfolioItem } from '@/utils/api-client';

type Props = {
  isModal: boolean;
  onModal: React.Dispatch<React.SetStateAction<boolean>>;
  setPortfolioTypeModal: React.Dispatch<React.SetStateAction<boolean>>;
  portfolioItems?: PortfolioItem[];
  userId?: string;
};

export const WorksDoneModal = ({
  isModal,
  onModal,
  setPortfolioTypeModal,
  portfolioItems,
  userId,
}: Props): React.ReactElement => {
  const history = useHistory();
  const newItemIndex = portfolioItems && portfolioItems.length !== 0 ? portfolioItems[portfolioItems?.length - 1].i_id : null;
  if (!newItemIndex || !userId) return <></>;

  const linkTo = () => {
    onModal(false);
    history.push(`/mypage/portfolio/${userId}/${newItemIndex}/edit`);
  };

  const onClick = () => {
    onModal(false);
    setPortfolioTypeModal(true);
  }

  return (
    <Modal
      isOpen={isModal}
      isClose={() => onModal(false)}
      className={'modal-contents_done w-624 sm:w-screen'}
    >
      <DataLayerPushContainer
        data={{
          event: 'pageView',
          actionType: 'page_view',
          actionName: 'modal_open',
          virtualPageName: 'delete-a-work',
        }}
      >
        <p className="mbx-typography--heading_2 mt-80 sm:mt-48">作品が登録されました！</p>
        <p className="mt-24 mbx-typography--body_1 sm:pl-24 sm:pr-24">登録した作品について詳細を入力しましょう。</p>
        <div className="mt-48 pl-80 pr-80 sm:pl-24 sm:pr-24">
          <BaseButton
            className="ml-8 mr-8"
            theme="primary"
            size="m"
            onClick={linkTo}
          >
            続けて詳細を入力する
          </BaseButton>
        </div>
        <p className="return-text text-12_14 font-bold mt-24" onClick={onClick}>
          別の作品を登録する
        </p>
        <p className="return-text text-12_14 font-bold mt-24 mb-80 sm:mb-48" onClick={() => onModal(false)}>
          TOPへもどる
        </p>
      </DataLayerPushContainer>
    </Modal>
  );
};
