import React, { useState, useEffect } from 'react';
import { eventList } from '@/definition/EVENT_LIST';
import { State } from '@/redux/state';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

export const Ed134 = (): React.ReactElement => {
  const isAuth = useSelector((state: State) => state.isAuth);
  const [isLogin, setIsLogin] = useState(false);
  const [deadline, setDeadline] = useState(false);

  useEffect(() => {
    if (isAuth) {
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }
  }, [isAuth]);

  useEffect(() => {
    const e = eventList.find(e => e.id === "0134");
    if (!e) return;
    const yyyymmdd = e.deadlineDate.split(' ')[0];
    const deadlineDate = new Date(`${yyyymmdd.replace(/-/g, "/")} 12:00:00`);
    const now = new Date;
    if (now > deadlineDate) setDeadline(true);
  }, []);

  const style = {
    img: 'border-gray-300 border-r border-b border-l'
  };
  return (
    <div className="event-contents">
      <div className="event-detail">
        <div className="md:-mx-16 sm:-mx-16">
          <img className={style.img} src="../images/Top/event/event_0134_mv.jpg" width="978" height="504" alt="" />
        </div>
        <h1 className="mt-56 sm:mt-30 text-29_46 sm:text-18_24 font-bold text-center">2/20（木）19:30 -<br />
          Webデザイナーとしての<br className="md:hidden lg:hidden" />キャリアパスの考え方と整理</h1>
        {deadline &&
          <section className="mt-30 sm:mt-24 mb-40 sm:mb-18">
            <div className="flex flex-col items-center justify-center px-16 py-25 sm:p-16 bg-gray-100">
              <p className="mb-10 lg:mb-16 text-18_33 sm:text-14_21 font-bold">このセミナーの受付は終了しています。</p>
              <p className="text-16_28 sm:text-14_21">定期的にセミナーを開催しておりますので、<br className="sm:hidden" />最新の開催情報は<a className="textLink" href="../event/">こちら</a>よりご確認ください。</p>
            </div>
          </section>
        }
        <section>
          <h2 className="text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6">以下に該当する方にオススメです。</h2>
          <div className="mt-30 sm:mt-16 p-40 sm:p-24 box-line text-left">
            <ul className="text-16_28 sm:text-14_21">
              <li className="flex mb-4">
                <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>転職を実際に考えている、もしくは、今後のキャリア形成について悩んでいる人</span>
              </li>
              <li className="flex mb-4">
                <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>事業会社／クライアントワークのどちらにしようか悩んでいる人</span>
              </li>
              <li className="flex mb-4">
                <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>Webデザイナーとして今後も活躍できるキャリアを選びたいが、どう考えて良いかわからない人</span>
              </li>
              <li className="flex mb-4">
                <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>Web業界での転職に向けて、しっかりとした準備を整えたい</span>
              </li>
              <li className="flex mb-4">
                <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>企業分析の考え方とまとめ方を知りたい</span>
              </li>
              <li className="flex mb-4">
                <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>業界のちょっとした裏話を聞いてみたい</span>
              </li>
              <li className="flex mb-4">
                <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>自身の今後のキャリアの方向性を定めたい</span>
              </li>
              <li className="flex">
                <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>セミナーを踏まえて転職の相談をしたい</span>
              </li>
            </ul>
          </div>
        </section>
        {!deadline && !isLogin &&
          <section className='mt-80 sm:mt-55 mb-40 sm:mb-18'>
            <div className='flex flex-col items-center justify-center'>
              <p className='mb-6 md:mb-12 lg:mb-12 text-16_28 sm:text-14_21'>MATCHBOXのアカウントをお持ちでない方</p>
              <p className='base-button mb-15 md:mb-24 lg:mb-24 text-16_28 sm:text-14_21'>
                <Link className='button size-m theme-secondary flex items-center flex-col font-bold' to="/entry"
                >
                  アカウントを新規で作成する
                  <span className='text-14_21 sm:text-12_20 text-center'>※セミナー参加にはMATCHBOXの事前登録が必要です。</span>
                </Link>
              </p>
              <p className='text-16_28 sm:text-14_21'>アカウントをお持ちの方は<Link className='textLink font-bold' to="/login">こちら</Link>からログイン後、お申込みください。</p>
            </div>
          </section>
        }
        <section className="mt-80 sm:mt-55">
          <h3 className="text-18_24 sm:text-14_21 font-bold">イベント内容</h3>
          <div className="mt-30 sm:mt-16 mb-30">
            <img src="../images/Top/event/event_0032_image.jpg" width="980" height="374" alt="" />
          </div>
          <p className="text-16_28 sm:text-14_21 mt-30">
            Webデザイナー向けに、Web業界で活躍するための転職活動において、聞いたその日から役に立つ内容を配信します。<br />
            <br />
            Webデザイナーに至ってはその幅広いデザイン業務において<br />
            <ul className="text-16_28 sm:text-14_21 my-16 sm:mt-8 sm:mb-8">
              <li className="flex mb-4">
                <span>・</span><span>制作環境</span>
              </li>
              <li className="flex mb-4">
                <span>・</span><span>必要なスキルやツール</span>
              </li>
              <li className="flex mb-4">
                <span>・</span><span>最新のテクノロジーのキャッチアップ</span>
              </li>
            </ul>
            などをどのような形で体験して、身に付けていくべきかお悩みの方も多いはず。これらを整理し、次のキャリアを戦略的に形成していくことがより重要になっています。<br />
            <br />
            そんな時代に必要不可欠となっているWebディレクター・Webデザイナーの皆様を支援するために、Webデザイナーとして15年目を迎え、多数の企業でWebデザイナー&amp;クリエイティブディレクターとして活動し、現在、株式会社BuzzreachでUI/UX/Webデザイナーとして活躍中の木村よねこ氏を講師に迎えて、「Webデザイナーとしてのキャリアパスの考え方と整理」と題し、転職活動を行ううえで実践的に使える講義をオンラインで行います。<br />
            <br />
            興味がある方はお早めにご応募ください。
          </p>
        </section>
        <section className="mt-80 sm:mt-55">
          <h3 className="text-18_24 sm:text-14_21 font-bold">参加者の声</h3>
          <div className="mt-30 mb-15 sm:mt-16 sm:mb-8 p-40 sm:p-24 box-line text-left">
            <ul className="text-16_28 sm:text-14_21">
              <li className="flex mb-4">
                <span>・</span><span>実際に活躍されている方の話を聞いて、業界に対する解像度、キャリア形成の仕方の見通しがついた。<br />（25歳 制作会社 グラフィックデザイナー 3年目）</span>
              </li>
              <li className="flex mb-4">
                <span>・</span><span>キャリアの戦略や自己分析のしかた情報収集の仕方がよく分かった。<br />（26歳 Webデザイナー 4年目）</span>
              </li>
              <li className="flex">
                <span>・</span><span>個人でデザイナーの将来像を考えたときは、専門性が高くなることに乗じて選択肢が狭まるような認識があったが、お話を聴き、将来像をより広く考えられるきっかけになった。<br />（30歳 制作会社 Webデザイナー 8年目）</span>
              </li>
            </ul>
          </div>
          <p className="text-right text-16_28 sm:text-14_21">※参加者の声の一部を掲載しています</p>
        </section>
        {!deadline && !isLogin &&
          <section className='mt-80 sm:mt-55 mb-40 sm:mb-18'>
            <div className='flex flex-col items-center justify-center'>
              <p className='mb-6 md:mb-12 lg:mb-12 text-16_28 sm:text-14_21'>MATCHBOXのアカウントをお持ちでない方</p>
              <p className='base-button mb-15 md:mb-24 lg:mb-24 text-16_28 sm:text-14_21'>
                <Link className='button size-m theme-secondary flex items-center flex-col font-bold' to="/entry"
                >
                  アカウントを新規で作成する
                  <span className='text-14_21 sm:text-12_20 text-center'>※セミナー参加にはMATCHBOXの事前登録が必要です。</span>
                </Link>
              </p>
              <p className='text-16_28 sm:text-14_21'>アカウントをお持ちの方は<Link className='textLink font-bold' to="/login">こちら</Link>からログイン後、お申込みください。</p>
            </div>
          </section>
        }
        <section>
          <h2 className="text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6">講師プロフィール</h2>
          <div className="event-instructor box-line sm:p-16">
            <div className="instructor-photo">
              <img src="../images/Top/event/event_0006_prof.jpg" alt="" />
            </div>
            <dl className="instructor-prof sm:mt-16">
              <dt className="instructor-name text-16_28 font-bold">
                木村 よねこ<span className="inline-block">（YONECO KIMURA）</span>
              </dt>
              <dd className="instructor-position text-16_28 sm:text-14_21 mb-40 sm:mb-18">
                株式会社Buzzreach UI/UX/Webデザイナー
              </dd>
              <dd className="instructor-career text-16_28 sm:text-14_21">
                茨城県出身、筑波研究学園専門学校グラフィックデザイン学科卒業。外食産業で制作デザイナーとして、メニュー・ポスター、広報誌などの販促物を手がける。後に上京してWebデザイナー兼クリエイティブディレクターとして多数の企業で働く。GMOTECH株式会社ではSEO事業部でコーダー兼Webデザイナー、広告事業部でディレクターを経験。AIS株式会社では立ち上げメンバーとして新規サービス・メディアのサイト制作やディレクションにも携わる。現在株式会社Buzzreachにてデザインの内製化対応や自社サービスのWeb / UIUXデザインを担当。
              </dd>
            </dl>
          </div>
        </section>
        <section>
          <h2 className="text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6">当日スケジュール</h2>
          <table className="index timetable text-16_28 sm:text-14_21 mb-30 sm:mb-12">
            <tbody>
              <tr>
                <th scope="row" className="bg-gray-50 font-normal whitespace-nowrap" rowSpan={2}>
                  <span className="sm:block">19：30</span><span className="sm:block">～</span><span className="sm:block">21：00</span>
                </th>
                <td>
                  ＜前半＞ キャリアパスの考え方
                  <ul className="text-16_28 sm:text-14_21">
                    <li className="flex"><span>・</span><span>自己紹介</span></li>
                    <li className="flex"><span>・</span><span>Webデザイナーとは</span></li>
                    <li className="flex"><span>・</span><span>Webデザイナーからのキャリアパスの可能性</span></li>
                    <li className="flex"><span>・</span><span>Webデザイナーとしてのキャリア戦略と転職への考え方</span></li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>
                  ＜後半＞ 整理
                  <ul className="text-16_28 sm:text-14_21">
                    <li className="flex"><span>・</span><span>キャリアの振り返りと未来像の整理</span></li>
                    <li className="flex"><span>・</span><span>自己分析とターゲット調査、スキル整理</span></li>
                    <li className="flex"><span>・</span><span>事例紹介（実際に私が行ったことなど）</span></li>
                    <li className="flex"><span>・</span><span>さいごに</span></li>
                    <li className="flex"><span>・</span><span>質疑応答</span></li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        {!deadline && !isLogin &&
          <section className='mt-80 sm:mt-55 mb-40 sm:mb-18'>
            <div className='flex flex-col items-center justify-center'>
              <p className='mb-6 md:mb-12 lg:mb-12 text-16_28 sm:text-14_21'>MATCHBOXのアカウントをお持ちでない方</p>
              <p className='base-button mb-15 md:mb-24 lg:mb-24 text-16_28 sm:text-14_21'>
                <Link className='button size-m theme-secondary flex items-center flex-col font-bold' to="/entry"
                >
                  アカウントを新規で作成する
                  <span className='text-14_21 sm:text-12_20 text-center'>※セミナー参加にはMATCHBOXの事前登録が必要です。</span>
                </Link>
              </p>
              <p className='text-16_28 sm:text-14_21'>アカウントをお持ちの方は<Link className='textLink font-bold' to="/login">こちら</Link>からログイン後、お申込みください。</p>
            </div>
          </section>
        }
        <section className="mb-64 sm:mb-48">
          <h2 className="text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 sm:mb-8 sm:pb-6 text-left">開催概要</h2>
          <table className="summary text-16_28 sm:text-14_21">
            <tbody>
              <tr>
                <th scope="row" className="font-normal text-left">開催日時</th>
                <td>2025/2/20（木）19:30～<br />
                  [入室開始] 19:20～</td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">開催方法</th>
                <td>Zoom開催<br />参加者の皆さまには別途URLをお送りします。</td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">参加費</th>
                <td>無料</td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">対象者</th>
                <td>転職を実際に考えている、もしくは、今後のキャリア形成について考えている事業会社／制作会社のWebデザイナー</td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">応募手順</th>
                <td>
                  <dl>
                    <dt className="mb-18 sm:mb-8">1.参加申し込み</dt>
                    <dd className="mb-38 sm:mb-16">MATCHBOXへログインいただき、お申し込みください。（アカウント作成は<a className="textLink"
                      href="https://matchbox.work/entry">こちら</a>から）</dd>
                    <dt className="mb-18 sm:mb-8">2.ご連絡</dt>
                    <dd className="mb-38 sm:mb-16">マッチボックス セミナー事務局より、MATCHBOXに登録していただいているメールアドレス宛に当日の詳細のご連絡差し上げます。</dd>
                    <dt className="mb-18 sm:mb-8">3.開催当日</dt>
                    <dd>当日はZoomにて開催いたします。</dd>
                  </dl>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        {!deadline && !isLogin &&
          <section className='mt-80 sm:mt-55 mb-40 sm:mb-18'>
            <div className='flex flex-col items-center justify-center'>
              <p className='mb-6 md:mb-12 lg:mb-12 text-16_28 sm:text-14_21'>MATCHBOXのアカウントをお持ちでない方</p>
              <p className='base-button mb-15 md:mb-24 lg:mb-24 text-16_28 sm:text-14_21'>
                <Link className='button size-m theme-secondary flex items-center flex-col font-bold' to="/entry"
                >
                  アカウントを新規で作成する
                  <span className='text-14_21 sm:text-12_20 text-center'>※セミナー参加にはMATCHBOXの事前登録が必要です。</span>
                </Link>
              </p>
              <p className='text-16_28 sm:text-14_21'>アカウントをお持ちの方は<Link className='textLink font-bold' to="/login">こちら</Link>からログイン後、お申込みください。</p>
            </div>
          </section>
        }
        <section className="mt-80 sm:mt-55">
          <h3 className="text-18_24 sm:text-14_21 font-bold">開催について</h3>
          <p className='text-16_28 sm:text-14_21 mt-30 sm:mt-16'>Web・IT業界で必要不可欠な存在であるWebディレクター・Webデザイナーの皆さまを支援するために、日本ディレクション協会で受講者累計2, 000名を超える「0からのWebディレクション講座」を生み出したメンバーでもある一般社団法人ディレクションサポート協会 会長の助田正樹氏と連携して、Webの現場最前線で働くロールモデルの方々をゲストとしてお呼びし、キャリアを考えるために有益な情報提供を開始しています。</p>
        </section>
        <section className="mt-80 sm:mt-55 p-40 sm:p-24 bg-gray-50 text-left">
          <h3 className="text-18_24 sm:text-14_21 font-bold">注意点</h3>
          <ul className="mt-16 text-16_28 sm:text-14_21">
            <li className="flex"><span>・</span><span>セミナー開催用のZoomURLはセミナー前日・当日にメールにてお送りします。</span></li>
            <li className="flex"><span>・</span><span>セミナー開催用のZoomURLをお知らせするメールはマッチボックスにご登録いただいているメールアドレス（ログインID）に送付しております。</span></li>
            <li className="flex"><span>・</span><span>受信フォルダ・迷惑メール設定、フォルダの受信容量やドメインの許可（@matchbox.work）の確認をお願いいたします。</span></li>
            <li className="flex"><span>・</span><span>マッチボックス登録時のアドレス、またはセミナー申込み時に入力したアドレスにURLへ届いていない場合は<a className='textLink' href='https://survey.mynavi.jp/cre/?enq=2va0sJC992s%3d' target={'_blank'} rel="noreferrer">こちら<i className="mbx-icon mbx-icon-External_link"></i></a>からお問い合わせください。</span></li>
          </ul>
        </section>
        <div className='back_event-list flex flex-col items-center justify-center mt-120 sm:mt-55'>
          <div className="base-button isTextLink">
            <a className="button size-s theme-link flex items-center" href="./"><i className="mbx-icon mbx-icon-Arrow_Left mr-2"></i><span className="font-bold label">イベント一覧へ</span></a>
          </div>
        </div>
      </div>
    </div>
  );
};
